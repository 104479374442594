import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface RowsProps {
  title: string[];
  count: number[];
  index: number;
}

const TableComp = (rows: RowsProps) => {
  return (
    <TableContainer component={Paper} className="rounded-2xl bg-runticketGray">
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" className="text-xl font-bold text-white">
              注文一覧 ({rows.index}件)
            </TableCell>
            <TableCell align="right" className="text-xs font-bold text-white">
              個数
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.title.map((title: string, i: number) => (
            <TableRow key={i}>
              <TableCell align="left" className="text-xl text-white">
                {title}
              </TableCell>
              <TableCell align="right" className="text-xl text-white">
                {rows.count[i]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComp;
