import { LeapFrog, Orbit, Ping } from "@uiball/loaders";

interface LoadingAnimationProps {
  type: "leapfrog" | "orbit" | "ping";
  top?: string;
  bottom?: string;
}
export const LoadingAnimation = (props: LoadingAnimationProps) => {
  return (
    <>
      {props.type === "leapfrog" && (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: props.top,
              bottom: props.bottom,
              right: "50%",
              transform: "translate(50%,50%)",
            }}
          >
            <LeapFrog size={100} speed={1.8} color="#ffffff" />
          </div>
        </div>
      )}
      {props.type === "ping" && (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: props.top,
              bottom: props.bottom,
              right: "50%",
              transform: "translate(50%,20%)",
            }}
          >
            <Ping size={150} speed={1.8} color="#ffffff" />
          </div>
        </div>
      )}
      {props.type === "orbit" && (
        <div style={{ position: "relative", width: "100%", height: "12vw" }}>
          <div
            style={{
              position: "absolute",
              top: props.top,
              right: "50%",
              transform: "translate(50%,-50%)",
            }}
          >
            <Orbit size={60} speed={1.2} color="#006C9B" />
          </div>
        </div>
      )}
    </>
  );
};
