import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorPage } from "../views/ErrorPage";
import { useState } from "react";
import { OrderStatusList } from "../views/OrderStatusList";
import Admin from "../views/Admin";
import { ImageUpload } from "../views/ImageUpload";
import { Authentication } from "../views/Authentication";
import { AuthProvider } from "../api/AuthProvider";
const Router = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [isLocalData, setIsLocalData] = useState(false);
  return (
    <BrowserRouter>
      <AuthProvider setIsLogin={setIsLogin} setIsLocalData={setIsLocalData}>
        <Routes>
          {isLogin && isLocalData ? (
            <>
              <Route path="/" element={<Admin />} />
              <Route path="/status" element={<OrderStatusList />} />
              <Route path="/upload" element={<ImageUpload />} />
              <Route path="/login" element={<Authentication />} />
              <Route
                path="*"
                element={
                  <ErrorPage
                    text={"お探しのページは見つかりませんでした"}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                    buttonText={"ホームに戻る"}
                  />
                }
              />
            </>
          ) : (
            <Route path="*" element={<Authentication />} />
          )}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
