import { Card } from "@mui/material";
import classNames from "classnames";
// import { useState } from "react";

interface SelectQuantityProps {
  selectNumber: number;
  setSelectNumber: (selectNumber: number) => void;
}

export const SelectQuantity = (props: SelectQuantityProps) => {
  const selectNumber = [0, 5, 10, 20, 30, 100];
  return (
    <div className="my-2 grid w-full grid-flow-col gap-2 overflow-x-scroll">
      {selectNumber.map((number, index: number) => (
        <button key={index} onClick={() => props.setSelectNumber(number)}>
          <Card
            className={classNames(
              "flex h-20 w-20 items-center justify-center border-2 border-runticketBlue/20",
              {
                "bg-runticketBlue/20": props.selectNumber === number,
              }
            )}
          >
            {number === 0 ? "売り切れ" : number}
          </Card>
        </button>
      ))}
    </div>
  );
};
