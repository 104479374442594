import { Button, Grid } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  AllMenuDataUpdate,
  IsGetSystemStatus,
  MenuDataUpdate,
  updateSystemStatus,
} from "../api/helper";
import { MenuData } from "../api/types";
import { ChangeMenuStatusDialog } from "../component/ChangeMenuStatusDialog";
import { LoadingAnimation } from "../component/LoadingAnimation";
import { db } from "../api/Firebase";
import classNames from "classnames";
import { Modal } from "@mui/material";
import { IOSSwitch } from "../component/IOSSwitch";
import { Link } from "react-router-dom";

export const OrderStatusList = () => {
  const [isCloseLoading, setIsCloseLoading] = useState(false);
  const [menuData, setMenuData] = useState<MenuData[]>([]);
  const [allMenuStatus, setAllMenuStatus] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [allMenuQuantity, setAllMenuQuantity] = useState(0);
  const [isStatus, setIsStatus] = useState<boolean>();
  const [isModal, setIsModal] = useState(false);
  useEffect(() => {
    window.document.title = "RunTicket for Restaurant";
    (async () => {
      const q = query(collection(db, "menu"), where("isStatus", "==", true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setMenuData((menuData) => [...menuData, doc.data() as MenuData]);
      });
      setIsStatus(await IsGetSystemStatus());
      setIsCloseLoading(true);
    })();
    console.log("useEffect");
  }, []);

  return (
    <main
      style={{
        background: "linear-gradient(200deg, #00566b 0%, #1c1b64 100%)",
      }}
    >
      {isCloseLoading ? (
        <div className="py-[50px]">
          <div className="flex w-full flex-col items-center justify-center">
            <Button
              disabled={buttonIsDisabled}
              className="mt-5 w-[500px] rounded-2xl bg-runticketBlue py-5 text-3xl font-bold text-white"
              color="info"
              onClick={() => {
                setAllMenuStatus(true);
              }}
            >
              全メニュー販売状況を変更する
            </Button>
          </div>
          <div className="mx-auto my-4 grid w-5/6 grid-cols-12 items-center divide-x border border-solid bg-black/60 p-2 text-xl">
            <p className="col-span-7 text-left font-bold text-white">
              システム状態
            </p>
            <div className="col-span-5 text-right font-bold text-white">
              <span>停止</span>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isStatus}
                onChange={async () => {
                  setIsModal(true);
                }}
              />
              <span>起動</span>
            </div>
          </div>
          <div className="mb-[60px]">
            {menuData.length !== 0 && (
              <h2 className="my-5 text-center text-[30px] text-white">
                メイン
              </h2>
            )}
            {menuData
              .filter((e) => e.category === "メイン")
              .map((menu, index) => {
                return (
                  <ListContent
                    menuData={menu}
                    key={index}
                    isSendDisabled={menu.quantity <= 0}
                    isSystem={isStatus}
                  />
                );
              })}
            {menuData.length !== 0 && (
              <h2 className="my-5 text-center text-[30px] text-white">
                ドリンク
              </h2>
            )}
            {menuData
              .filter((e) => e.category === "ドリンク")
              .map((menu, index) => {
                return (
                  <ListContent
                    menuData={menu}
                    key={index}
                    isSendDisabled={menu.quantity <= 0}
                    isSystem={isStatus}
                  />
                );
              })}
            {menuData.length !== 0 && (
              <h2 className="my-5 text-center text-[30px] text-white">
                トッピング
              </h2>
            )}
            {menuData
              .filter((e) => e.category === "トッピング")
              .map((menu, index) => {
                return (
                  <ListContent
                    menuData={menu}
                    key={index}
                    isSendDisabled={menu.quantity <= 0}
                    isSystem={isStatus}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <div className="h-[100dvh]">
          <LoadingAnimation type={"leapfrog"} top="40%" />
        </div>
      )}
      {menuData.length === 0 && isCloseLoading && (
        <div className="mt-[20vh] text-center text-[#C42A00]">
          現在、メニューデータがありません
        </div>
      )}
      <Modal
        open={isModal}
        onClose={() => {
          setIsModal(false);
        }}
      >
        <div className="fixed left-1/2 top-1/2 z-10 h-1/3 w-1/3 -translate-x-1/2 -translate-y-1/2 transform rounded-2xl bg-white p-5">
          <h2
            className={classNames(
              "mt-5 text-center text-2xl font-bold",
              { "text-red-600": isStatus },
              { "text-sky-600": !isStatus }
            )}
          >
            システムを{isStatus ? "停止" : "起動"}します。
            <br />
            よろしいですか？
          </h2>
          <p className="my-4 px-2">
            {isStatus
              ? "システムを停止するとモバイルオーダーはメンテナンス中となり、利用できなくなります。"
              : "起動するとモバイルオーダーは利用可能になります。"}
          </p>
          <div>
            <Button
              className="absolute bottom-2 left-2 mr-5 w-[100px] font-bold"
              onClick={() => {
                setIsModal(false);
              }}
            >
              閉じる
            </Button>
            <Button
              className={classNames(
                "absolute bottom-2 right-2 w-[100px] font-bold",
                { "bg-red-600": isStatus },
                { "bg-sky-600": !isStatus }
              )}
              variant="contained"
              onClick={async () => {
                setIsModal(false);
                setIsCloseLoading(false);
                await updateSystemStatus(!isStatus);
                setIsStatus(!isStatus);
                setIsCloseLoading(true);
              }}
            >
              {isStatus ? "停止" : "起動"}
            </Button>
          </div>
        </div>
      </Modal>
      <Link to="/">
        <Button
          fullWidth
          className="fixed bottom-0 left-0 h-[80px] text-3xl"
          variant="contained"
        >
          注文一覧に戻る
        </Button>
      </Link>
      <ChangeMenuStatusDialog
        title={"全メニュー"}
        open={allMenuStatus}
        okButtonText={allMenuQuantity === 0 ? "売り切れ" : "変更する"}
        noButtonText={"閉じる"}
        okButtonClassName={allMenuQuantity === 0 ? "bg-[#D3302F]" : undefined}
        menuData={menuData}
        isDisabled={buttonIsDisabled}
        setQuantity={setAllMenuQuantity}
        isSendDisabled={buttonIsDisabled}
        setIsSendDisabled={setButtonIsDisabled}
        quantity={allMenuQuantity}
        isAllChangeButton
        onConfirm={() => {
          setButtonIsDisabled(true);
          AllMenuDataUpdate(menuData, allMenuQuantity);
          setAllMenuStatus(false);
        }}
        onClose={() => {
          setAllMenuStatus(false);
        }}
      />
    </main>
  );
};

interface ListContentProps {
  menuData: MenuData;
  isSendDisabled?: boolean;
  isSystem?: boolean;
}
const ListContent = (props: ListContentProps) => {
  const [open, setOpen] = useState(false);
  const [selectQuantity, setSelectQuantity] = useState(0);
  const [isSendDisabled, setIsSendDisabled] = useState(props.isSendDisabled);
  const isSale: boolean = props.menuData.quantity > 0;
  return (
    <div
      className={classNames(
        "mx-auto my-5 w-[90%] rounded-xl py-5 text-center text-3xl text-runticketBlue",
        {
          "bg-white": isSale && props.isSystem,
          "bg-black/50": !isSale || !props.isSystem,
        }
      )}
    >
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item xs={9}>
          <div
            className={`grid grid-cols-[90%_auto] ${
              isSale ? "text-runticketBlue" : "text-[#D3302F]"
            }`}
          >
            <p className="text-start">
              {props.menuData.title} ({isSale ? "販売中" : "完売中"})
            </p>
            <p className="text-center">{props.menuData.quantity}</p>
          </div>
        </Grid>
        <Grid item xs={2}>
          {props.isSystem && (
            <Button
              onClick={() => {
                setOpen(true);
              }}
              className="h-10 bg-runticketBlue text-white"
            >
              <div className="w-20 text-[15px]">変更する</div>
            </Button>
          )}
        </Grid>
      </Grid>
      <ChangeMenuStatusDialog
        title={props.menuData.title}
        open={open}
        okButtonText={isSale && selectQuantity === 0 ? "売り切れ" : "変更する"}
        noButtonText={"閉じる"}
        okButtonClassName={
          isSale && selectQuantity === 0 ? "bg-[#D3302F]" : undefined
        }
        isDisabled={isSendDisabled || (!isSale && selectQuantity === 0)}
        onConfirm={() => {
          MenuDataUpdate(props.menuData, selectQuantity);
          setOpen(false);
        }}
        onClose={() => {
          setOpen(false);
          setSelectQuantity(0);
        }}
        menuData={props.menuData}
        quantity={selectQuantity}
        setQuantity={setSelectQuantity}
        setIsSendDisabled={setIsSendDisabled}
        isSendDisabled={isSendDisabled}
      />
    </div>
  );
};
