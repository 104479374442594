import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import { MultiplePurchase } from "./MultiplePurchase";
import { SelectQuantity } from "./SelectQuantity";
import { MenuData } from "../api/types";
interface Props {
  title: string;
  children?: React.ReactNode;
  open: boolean;
  okButtonText?: string;
  noButtonText?: string;
  onConfirm: () => void;
  onClose: () => void;
  isDisabled?: boolean;
  okButtonClassName?: string;
  menuData: MenuData | MenuData[];
  quantity: number;
  setQuantity: (quantity: number) => void;
  isSendDisabled?: boolean;
  setIsSendDisabled?: (isSendDisabled: boolean) => void;
  isAllChangeButton?: boolean;
}

export const ChangeMenuStatusDialog = (props: Props) => {
  return (
    <div>
      <Dialog
        fullWidth
        onClose={() => {
          props.onClose();
        }}
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.children}
          </DialogContentText>
          <div>
            <p>
              {props.title === "全メニュー"
                ? "全てのメニューの販売状況を一括で変更します。販売数を選択または入力してください。"
                : "を販売する個数を選択または入力してください"}
            </p>
            {!props.isAllChangeButton ? (
              <p className="text-end">
                現在の在庫数:
                {!Array.isArray(props.menuData) && props.menuData.quantity}
              </p>
            ) : null}
            <SelectQuantity
              selectNumber={props.quantity}
              setSelectNumber={props.setQuantity}
            />
            <MultiplePurchase
              purchaseCount={props.quantity}
              setPurchaseCount={props.setQuantity}
              setIsSendDisabled={props.setIsSendDisabled}
              isSetDisabled={props.isSendDisabled}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{props.noButtonText}</Button>
          <Button
            disabled={props.isDisabled}
            variant="contained"
            onClick={props.onConfirm}
            className={classNames("text-white", props.okButtonClassName)}
          >
            {props.okButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
