import { Card, FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { checkPassword, dateFormatter } from "../api/helper";
import { LoginStorageData } from "../api/types";

export const Authentication = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  useEffect(() => {
    const passWordData: LoginStorageData = {
      password: password,
      date: dateFormatter(new Date()),
    };
    if (checkPassword(password, setError)) {
      localStorage.setItem("login", JSON.stringify(passWordData));
      window.location.href = "/";
    }
  }, [password]);

  return (
    <main
      className="flex items-center justify-center"
      style={{
        background: "linear-gradient(200deg, #00566b 0%, #1c1b64 100%)",
      }}
    >
      <Card className="rounded-xl bg-white/50 p-8">
        <h1 className="text-center text-3xl font-bold text-white">
          PINコードを
          <br />
          入力してください
        </h1>
        <div className="m-2 p-2">
          <FormControl fullWidth variant="outlined">
            <TextField
              variant="outlined"
              type="text"
              value={password}
              inputMode="numeric"
              inputProps={{ pattern: "[0-9]*" }}
              onChange={(e) => setPassword(e.target.value)}
              label="例: 1234"
              error={error}
              helperText={error ? "PINコードが違います" : ""}
            />
          </FormControl>
        </div>
      </Card>
    </main>
  );
};
