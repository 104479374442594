import { useEffect } from "react";
import { LoginStorageData } from "./types";
import { dateFormatter } from "./helper";
import { useLocation } from "react-router-dom";

export const AuthProvider = (props: {
  setIsLocalData: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    const loginLocalStorage = JSON.parse(
      localStorage.getItem("login") || "[]"
    ) as LoginStorageData;
    props.setIsLocalData(loginLocalStorage !== null);
    if (loginLocalStorage.date === dateFormatter(new Date())) {
      props.setIsLogin(true);
    }
    // urlが変更された時に実行される
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLocation()]);
  return <>{props.children}</>;
};
